<template>
  <div>
    <div>
      <div>
        <van-nav-bar title="实名认证" left-arrow left-text="返回" z-index="99" placeholder safe-area-inset-top @click-left="onClickLeft" />
      </div>
      <div>
        <div style="padding: 30px 40px;display: flex;flex-direction: column;">
          <div class="auth-text">根据国家相关法律，您需要进行实名认证</div>
          <input v-model="person.realName" class="auth-input" :disabled="isAuth" placeholder="请输入真实姓名" />
          <input v-model="person.cardNo" class="auth-input" :disabled="isAuth" placeholder="请输入身份证号" />
          <van-button @click="realNameAuth" v-if="!isAuth" type="primary" block style="margin-top: 20px">立即认证</van-button>
          <div class="auth-text" style="margin-top: 20px">实名认证仅作为防沉迷功能，不用做其他用途</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getRealName, realNameAuth} from "@/api/request";

export default {
  name: "realNameAuth",
  data(){
    return {
      isAuth: true,
      person: {
        realName: '',
        cardNo: ''
      }
    }
  },
  mounted() {
    this.getRealNameInfo();
  },
  methods: {
    realNameAuth(){
      realNameAuth(this.person).then(res => {
        if(res.data.success){
          this.getRealNameInfo();
        }else {
          this.$toast.fail(res.data.error.message);
        }
      })
    },
    getRealNameInfo(){
      getRealName().then(res => {
        let info = res.data.data;
        this.isAuth = (info.cardNo && info.realName);
        if(this.isAuth){
          this.person.realName = info.realName;
          this.person.cardNo = info.cardNo;
        }
      })
    },
    onClickLeft() {
      this.$router.push('/security/page');
    }
  }
}
</script>

<style scoped src="../../../../static/css/setting.css"></style>
<style scoped>
/deep/ .van-button {
  border: 0;
  border-radius: 8px;
  background-color: #9ad1aa;
}
.auth-input {
  border: 0;
  border-radius: 8px;
  padding: 15px;
  background-color: #f0f2f4;
  margin-top: 20px;
}
.auth-text {
  font-size: 14px;
  color: #757575;
}
</style>
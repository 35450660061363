import axios from 'axios'
import SERVERS from '@/api/servers.js'
import {Toast} from "vant";

axios.defaults.baseURL = SERVERS.BASEURL

// http request 拦截器
axios.interceptors.request.use(
    config => {
      if(!config.headers['token']){
        config.headers['token'] = localStorage.getItem('token')
      }
      return config
    },
    err => {
      return Promise.reject(err)
    }
)

// 返回状态判断
axios.interceptors.response.use(
    (response) => {
      //登录token失效
      // console.log('response',response.data)
      if (response.data.error && response.data.error.code === '911') {
        localStorage.removeItem('token');
        if(location.pathname.includes('/h5') || location.pathname.includes('/sdk')){
          location.reload();
        }else {
          window.location.href = '/home';
        }
        Toast('登录已失效');
        //window.location.reload()
      }
      return response
    },
    (error) => {
      return error
    }
)
export default axios

<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

import {getIndex, getSearch} from "@/api/request";

export default {
  name: 'App',
  mounted() {

  },
  methods: {
  }
}
</script>

<style>
html,body{
  margin: 0;
  padding: 0;
  border: 0;
  background-color: #fafcfd;
}
ul {
  padding: 0;
  margin: 0;
}
ul li {
  list-style: none;
}
#app {
  font-family: 微软雅黑, Arial, sans-serif
}

input {
  caret-color:#9ad1aa;
}
input::-webkit-input-placeholder {
  color: #b8bec4;
}

/*定义滚动条高宽及背景
 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar
{
  width:5px;
  height:10px;
  background-color:#F5F5F5;
}
/*定义滚动条轨道
 内阴影+圆角*/
::-webkit-scrollbar-track
{
  /*-webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.3);*/
  border-radius:10px;
  background-color:#F5F5F5;
}
/*定义滑块
 内阴影+圆角*/
::-webkit-scrollbar-thumb
{
  border-radius:10px;
  /*-webkit-box-shadow:inset 0 0 6px rgba(0,0,0,.3);*/
  background-color: #8b8b8b;
}

</style>

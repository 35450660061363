<template>
  <div>
    <div class="c-container">
      <div class="c-login-head">
        <div class="flex flex-row flex-1 c-login-head__container">
          <div class="c-login-head__title">注册</div>
          <div>
            <van-icon name="cross" class="pointer" :color="iconColor" @mouseenter="setIconColor(0.7)" @mouseleave="setIconColor(1)" @click="close"/>
          </div>
        </div>
      </div>

      <div class="c-login-body">
        <div class="flex flex-column">
          <div>
            <input v-model.trim="registerParam.username" class="c-login-body__item-input" type="text" placeholder="请输入手机号" />
          </div>
          <div>
            <input v-model.trim="registerParam.password" class="c-login-body__item-input" type="password" placeholder="请输入密码" />
          </div>
          <div>
            <input v-model.trim="registerParam.confirmPassword" class="c-login-body__item-input" type="password" placeholder="请二次确认密码" />
          </div>
          <div>
            <div style="display: flex;flex-direction: row;justify-content: space-between;align-items: center;">
              <input v-model="registerParam.smsCode" class="c-login-body__item-input" style="width: calc(100% - 100px)" type="text" placeholder="请输入验证码" />
              <div class="c-send__verify" v-show="!showCountDown" @click="sendVerifyCode">发送验证码</div>
              <div ref="countDown" class="c-send__verify" v-show="showCountDown">{{ countDown }}</div>
            </div>
          </div>
          <div>
            <input v-model="registerParam.inviteCode" class="c-login-body__item-input" type="text" placeholder="请邀请码密码(选填)" />
          </div>
          <div class="mt-15">
            <div class="flex" style="justify-content: space-between;align-items: center;">
              <div></div>
              <div class="c-login-body__text-button" @click="toLogin">去登陆</div>
            </div>
          </div>
          <div class="mt-15">
            <van-button @click="doRegister" block color="#90e1ac" style="border-radius: 5px;">确认注册</van-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tools from "@/utils/Tools";
import Crypto from "@/utils/crypto";
import {sendVerifyCode, toRegister} from "@/api/request";

export default {
  name: "register",
  data(){
    return {
      consent: false,
      registerParam: {
        username: '',
        password: '',
        confirmPassword: '',
        smsCode: '',
        inviteCode: ''
      },
      showCountDown: false,
      countDown: 0,
      iconColor: 'rgba(13, 13, 13, 1)',
      channelId: '1',
    }
  },
  mounted() {
    this.channelId = localStorage.getItem("channel");
  },
  methods: {
    close(){
      this.$parent.close();
    },
    toLogin(){
      this.$parent.$parent.openLogin();
    },
    setIconColor(alpha){
      this.iconColor = `rgba(13, 13, 13, ${alpha})`;
    },
    doRegister(){
      if(!Tools.isPhone(this.registerParam.username)){
        this.$toast.fail("手机号码无效，请正确填写手机号码")
      }else if(!Tools.isPassWord(this.registerParam.password)){
        this.$toast.fail("密码为6-12位数字、字母、字符")
      }else if(!Tools.isPassWord(this.registerParam.confirmPassword)){
        this.$toast.fail("确认密码为6-12位数字、字母、字符")
      }else if(this.registerParam.password !== this.registerParam.confirmPassword){
        this.$toast.fail("两次输入的密码不一致")
      }else if(!this.registerParam.smsCode){
        this.$toast.fail("请输入验证码")
      }else {
        let params = {
          username: this.registerParam.username,
          password: Crypto.encrypt(this.registerParam.password),
          smsCode: this.registerParam.smsCode,
          inviteCode: this.registerParam.inviteCode,
          channelId: this.channelId,
        }
        toRegister(params).then(res => {
          if(res.data.success){
            this.registerParam = {
              username: '',
              password: '',
              confirmPassword: '',
              smsCode: '',
              inviteCode: ''
            }
            this.close();
          }else {
            this.$toast.fail(res.data.error.message)
          }
        })
      }
    },
    sendVerifyCode() {
      if(!Tools.isPhone(this.registerParam.username)){
        this.$toast.fail("手机号码无效，请正确填写手机号码")
        return;
      }
      let params = {
        type: 1,
        phone: this.registerParam.username
      }
      sendVerifyCode(params).then(res => {
        if (res.data.success) {
          let time = 120;
          let timer = setInterval(() => {
            this.showCountDown = true;
            this.countDown = time + "s";
            time--;
            if (time < 0) {
              this.showCountDown = false;
              clearInterval(timer);
            }
          }, 1000);
        } else {
          alert(res.data.error.message)
        }
      }).catch(() => {
      })
    },
  }
}
</script>

<style src="../../static/css/network-auth.css"></style>
<style scoped>

</style>
<template>
  <div>
    <div>
      <div>
        <van-nav-bar title="账号管理" left-arrow left-text="返回" z-index="99" placeholder safe-area-inset-top @click-left="onClickLeft" />
      </div>
      <div>
        <div class="setting-view">
          <router-link :to="c.path" class="base-flex-row label-link" style="color: #252525;" v-for="c in itemList" :key="c.label">
            <div style="font-size: 14px;">{{ c.label }}</div>
            <div>
              <van-icon name="arrow" size="14" color="#75787b"/>
            </div>
          </router-link>
        </div>
      </div>
    </div>

    <van-action-sheet v-model="showBindPhone" title="标题">
      <div class="content">内容</div>
    </van-action-sheet>
  </div>
</template>

<script>
export default {
  name: "securityPage",
  data(){
    return {
      itemList: [
        {label: '实名认证', path: '/realName'},
        // {label: '手机绑定', path: ''},
        {label: '修改密码', path: '/modify/password'},
      ],
      showBindPhone: false,
    }
  },
  methods: {
    onClickLeft() {
      this.$router.push('/setting');
    },
  }
}
</script>
<style scoped src="../../../../static/css/setting.css"></style>
<style scoped>
/deep/ .van-nav-bar__left{
  padding-left: 8px;
}
.setting-view {
  width: 90%;
}

.label-link {
  margin-top: 20px;
}
.label-link:first-child {
  margin-top: 0;
}
</style>
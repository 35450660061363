<template>
  <div>
    <div>
      <div>
        <van-nav-bar title="修改密码" left-arrow left-text="返回" z-index="99" placeholder safe-area-inset-top @click-left="onClickLeft" />
      </div>
      <div class="setting-flex-column">
        <div class="bind-view">
            <input v-model="changePasswordParam.oldPwd" class="bind-input" type="password" placeholder="请输入原密码"/>
            <input v-model="changePasswordParam.newPwd" class="bind-input" type="password" placeholder="请输入新密码"/>
            <input v-model="changePasswordParam.confirmNewPwd" class="bind-input" type="password" placeholder="请输入确认新密码"/>
        </div>
        <div style="font-size: 12px;color: #757575;margin-top: 20px;">密码为6-12位数字、字母和特殊字符</div>
        <van-button @click="updatePassword" round block color="linear-gradient(120deg, #8fe5ad 0%, #97d6ab 100%)" style="margin-top: 20px;">
          确认修改
        </van-button>
      </div>
      <van-action-sheet v-model="showActionSheet" title="验证手机号" :close-on-click-overlay="false" @close="verifyCode = ''">
        <div style="padding: 15px;display: flex;flex-direction: column;row-gap: 20px;">
          <div>
            请验证手机号{{ phone }}
          </div>
          <div>
            <van-password-input
                :value="verifyCode"
                :mask="false"
                :focused="showKeyboard"
                @focus="showKeyboard = true"
            />
            <van-number-keyboard
                v-model="verifyCode"
                :show="showKeyboard"
                @blur="showKeyboard = false"
                @input="onPasswordInput"
            />
          </div>
          <div>
            <van-button @click="sendVerifyCode" :disabled="countDown !== 0" round block color="linear-gradient(120deg, #8fe5ad 0%, #97d6ab 100%)">
              <span v-if="countDown === 0">获取验证码</span>
              <span v-else>{{ countDown + 's' }}</span>
            </van-button>
          </div>
        </div>
      </van-action-sheet>
    </div>
  </div>
</template>

<script>
import {changePassword, logout, sendVerifyCode, verifyCode} from "@/api/request";
import Crypto from "@/utils/crypto";
import tools from "@/utils/Tools";
import Tools from "@/utils/Tools";

export default {
  name: "modifyPassword",
  data(){
    return {
      changePasswordParam: {
        oldPwd: '',
        newPwd: '',
        confirmNewPwd: ''
      },
      showActionSheet: false,
      verifyCode: '',
      showKeyboard: false,
      countDown: 0,
      phone: '',
    }
  },
  methods: {
    checkVerify(){
      this.phone = JSON.parse(localStorage.getItem("user_info")).userName;
      this.phone = tools.idcardDesensitization('',this.phone).mobile;
      this.showActionSheet = true;
    },
    updatePassword() {
      if(!this.changePasswordParam.newPwd || !this.changePasswordParam.confirmNewPwd){
        this.$toast.fail("请输入密码");
        return;
      }
      if(this.changePasswordParam.newPwd !== this.changePasswordParam.confirmNewPwd){
        this.$toast.fail("两次密码不一致");
        return;
      }
      let params = {
        oldPassword: Crypto.encrypt(this.changePasswordParam.oldPwd),
        newPassword: Crypto.encrypt(this.changePasswordParam.newPwd),
      }
      changePassword(params).then(res => {
        if(res.data.success){
          this.$toast.success('修改成功，请重新登录');
          logout().then(res => {
            localStorage.removeItem("web_token");
            localStorage.removeItem("user_info");
            this.$router.push('/home');
          })
        }else {
          this.$toast.fail(res.data.error.message);
        }
      })
    },
    sendVerifyCode() {
      if(this.countDown !== 0) {
        this.$toast("倒计时还剩" + this.countDown + "s");
        return;
      }
      let params = {
        type: 1,
        phone: JSON.parse(localStorage.getItem("user_info")).userName
      }
      sendVerifyCode(params).then(res => {
        if (res.data.success) {
          let time = 120;
          let timer = setInterval(() => {
            this.countDown = time;
            time--;
            if (time < 0) {
              clearInterval(timer);
            }
          }, 1000);
        } else {
          alert(res.data.error.message)
        }
      }).catch(() => {
      })
    },
    onPasswordInput(value){
      this.verifyCode = this.verifyCode + value;
      if(this.verifyCode.length === 6){
        let username = JSON.parse(localStorage.getItem("user_info")).userName;
        verifyCode(this.verifyCode,username).then(res => {
          if(res.data.success){
            this.updatePassword();
            this.showKeyboard = false;
          }else {
            this.$toast.fail(res.data.error.message);
          }
        })
      }
    },
    onClickLeft(){
      this.$router.push('/security/page');
    }
  }
}
</script>

<style scoped src="../../../../static/css/setting.css"></style>
<style scoped>
/deep/ .van-password-input {
  margin: 0;
}
/deep/ .van-password-input__security {
  column-gap: 5px;
}
/deep/ .van-password-input__security li{
  background-color: #efefef;
}
/deep/ .van-nav-bar__left{
  padding-left: 8px;
}
.setting-view {
  width: 90%;
}
.bind-input {
  padding: 15px 0;
}
.bind-view input:nth-child(1){
  border-bottom: 1px solid #e1e1e1
}
.bind-view input:nth-child(2){
  border-bottom: 1px solid #e1e1e1
}
</style>
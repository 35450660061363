<template>
  <div>
    <div class="search-main__view" ref="view">
      <div ref="topContainer" class="top-container">
        <div class="top-download" v-if="showDownload">
          <div>
            <img src="../static/logo/sw-logo-t.png" width="40" height="40" />
          </div>
          <button class="download-button" @click="$router.push('/download')">下载APP</button>
        </div>
        <div class="search-container"  ref="searchContainer">
          <div class="game-search-view">
            <input ref="input" v-model="search" :class="{'game-search-input-text': isInputShrink}" class="game-search-input" style="pointer-events: auto" @input="onInput" @focus="onInputFocus" @blur="onInputBlur" />
            <svg v-show="!hiddenIcon" t="1720604717925" class="game-search-icon icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5230" width="200" height="200">
              <path d="M451.946667 768A316.266667 316.266667 0 1 1 768 452.053333 316.586667 316.586667 0 0 1 451.946667 768z m0-589.76A273.6 273.6 0 1 0 725.333333 452.053333a273.92 273.92 0 0 0-273.386666-273.6z" fill="#252525" p-id="5231"></path>
              <path d="M644.48 416a21.333333 21.333333 0 0 1-19.2-11.946667 275.413333 275.413333 0 0 0-132.16-128.64 21.333333 21.333333 0 0 1 17.706667-38.826666 318.08 318.08 0 0 1 152.746666 148.693333 21.333333 21.333333 0 0 1-9.706666 28.586667 21.333333 21.333333 0 0 1-9.386667 2.133333z" fill="#252525" p-id="5232"></path>
              <path d="M816.426667 832a21.333333 21.333333 0 0 1-15.04-6.293333l-112.106667-112a21.333333 21.333333 0 0 1 30.186667-30.186667L832 795.626667a21.333333 21.333333 0 0 1 0 30.08 21.333333 21.333333 0 0 1-15.573333 6.293333z" fill="#252525" p-id="5233"></path>
            </svg>
            <div class="swipe__container" style="pointer-events: none">
              <van-swipe ref="swipe" @change="onSwiperChange" :width="300" :autoplay="3000" vertical :touchable="false" :show-indicators="false">
                <van-swipe-item v-for="(item,index) in searchSwipeData" :key="index" class="search-input-hint"> {{ item.gameName }} </van-swipe-item>
              </van-swipe>
            </div>
          </div>
          <div ref="searchBtnView" class="game-search-button-view">
            <button @click="toSearch" ref="searchBtn" class="game-search-button">搜索</button>
          </div>
          
        </div>
      </div>
      
      <div v-if="false" class="search-hint-view" @mouseleave="onMouseLeaveSearchHint">
        <div>
          <div v-show="showHistory">
            <div class="base-flex-row">
              <div class="history-record-title gradient-text">历史记录</div>
              <div style="color: #757575;font-size: 14px;">
                <van-icon name="delete-o" color="#757575"/>
                <span style="cursor: pointer" @click="cleanHistory">清空</span>
              </div>
            </div>
            <div class="history-record">
              <div @click="onClickHistory(item)" class="history-record-item" v-for="(item,index) in historyData" :key="index">{{ item }}</div>
            </div>
          </div>
          <div style="padding-top: 20px;">
            <div class="base-flex-row">
              <div class="history-record-title gradient-text">热搜游戏</div>
            </div>
            <div class="hot-search">
              <div class="hot-search-item" @click="onClickHistory(item.gameName)" v-for="(item,index) in hotSearch" :key="index">
                <div>
                  <div style="font-size: 14px;">
                    {{ item.gameName }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {getHotSearch} from "@/api/request";

export default {
  name: "searchBox",
  props: {
    searchText: {
      type: String,
      default: ""
    },
    showDownload: {
      type: Boolean,
      default: false,
    },
    searchSwipeData: {
      type: Array,
      default: () => []
    }
  },
  data(){
    return {
      hiddenIcon: false,
      isHint: false,
      searchHistory: 'searchHistory',
      hotSearch: [],
      historyData: [],
      showHistory: false,
      search: this.searchText,
      isInputShrink: false,
      swipeIndex: 0,
    }
  },
  watch: {
    showDownload: {
      handler(value){
        if(!value){
          this.$nextTick(() => {
            this.$refs.view.style.height = "50px";
            this.$refs.searchContainer.style.lineHeight = "50px";
          })
        }
      },
      immediate: true,
    }
  },
  mounted() {
    this.checkHistory();
    this.onResize();
    this.getHotSearch();
    window.addEventListener('resize',this.onResize);
  },
  methods: {
    getHotSearch(){
      getHotSearch().then(res => {
        this.hotSearch = res.data.data;
      })
    },
    setHistory(){
      let history = JSON.parse(localStorage.getItem(this.searchHistory));
      if(!history){
        history = [];
      }
      if (history.filter(f => f === this.search).length === 0 && this.search) {
        history.push(this.search);
        localStorage.setItem(this.searchHistory, JSON.stringify(history));
      }
    },
    toSearch(){
      this.setHistory();
      this.$router.push(`/gameSearch/${this.search}`)
      this.isInputShrink = false;
      this.isHint = false;
    },
    checkHistory(){
      const history = JSON.parse(localStorage.getItem(this.searchHistory));
      this.showHistory = !!history;
      this.historyData = history;
    },
    cleanHistory(){
      localStorage.removeItem(this.searchHistory);
      this.historyData= [];
      this.showHistory = false;
    },
    onClickHistory(item){
      !!item && (this.search = item);
      this.setHistory();
      this.$router.push(`/gameSearch/${this.search}`)
      this.isInputShrink = false;
      this.isHint = false;
    },
    onInput(e){
      if(e.data || this.search){
        this.isInputShrink = true;
      }
    },
    onInputFocus(e){
      // this.checkHistory();
      // this.hiddenIcon = true;
      // this.isHint = true;
      // let searchBtnView = this.$refs.searchBtnView.style;
      // setTimeout(() => {
      //   searchBtnView.display = "block";
      // },10)
      let game = "";
      if(this.searchSwipeData.length !== 0 ) {
        game = this.searchSwipeData[this.swipeIndex].gameName;
      }
      this.$router.push(`/game/search/${game}`);
      
    },
    onInputBlur(e){
      // this.hiddenIcon = false;
      // let searchBtnView = this.$refs.searchBtnView.style;
      // if(!this.search){
      //   setTimeout(() => {
      //     this.isHint = false;
      //     searchBtnView.display = "none";
      //   },10)
      // }
    },
    onMouseLeaveSearchHint(){
      this.isHint = false;
      this.isInputShrink = false;
      this.hiddenIcon = false;
      let searchBtnView = this.$refs.searchBtnView.style;
      setTimeout(() => {
        this.$refs.input.blur();
        searchBtnView.display = "none";
      },10)
    },
    onSwiperChange(index){
      this.swipeIndex = index;
    },
    onResize(){
      this.$refs.topContainer && this.$refs.topContainer.style && (this.$refs.topContainer.style.width = this.$refs.view.clientWidth + "px");
    }
  }
}
</script>
<style scoped src="../static/css/game-search.css" />
<style scoped>
.search-main__view {
  position: relative;
  /*width: 540px;*/
  height: 60px;
  z-index: 300;
}
.top-container {
  background-color: #fafcfd;
  position: fixed;
  /*z-index: 999;*/
  /*width: inherit;*/
  height: inherit;
}
.search-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 50px;

  position: relative;
}
.search-hint-view {
  min-height: 400px;
  max-height: 600px;
  position: absolute;
  top: 40px;
  left: 0;
  right:0;
  z-index: 999;
  margin-top: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 2px 8px 30px rgba(0,0,0,.06),0 0 10px rgba(0,0,0,.04);
  overflow: hidden;
  overflow-y: auto;
  padding: 1rem;
}
.gradient-text {
  background: linear-gradient(to right, #9BE15D, #00E3AE); /*设置渐变的方向从左到右*/
  -webkit-background-clip: text;/*将设置的背景颜色限制在文字中*/
  -webkit-text-fill-color: transparent;/*给文字设置成透明*/
}
.history-record-title {
  font-size: 16px;
}

.hot-search {
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(2,1fr);
  column-gap: 10px;
  overflow: hidden;
  overflow-y: auto;
}
.hot-search-item {
  padding: 10px;
  cursor: pointer;
}
.hot-search-item:hover {
  background-color: #e1e1e1;
  border-radius: 8px;
}
.top-download {
  display: none;
}
.download-button {
  height: 30px;
  background-color: #9ad1aa;
  padding: 5px 10px;
  border: 0;
  border-radius: 99px;
  user-select: none;
  color: #f0f2f4;
  font-size: 14px;
  font-weight: 600;
}
.game-search-input {
  display: inline-block;
  width: calc(100% - 30px);
  height: 30px;
  padding: 5px 15px;
  border: 0;
  outline: none;
  border-radius: 9999px;
  background-color: #f0f2f4;
}
.search-input-hint {
  height: 20px;
  color: #aaa;
}
.swipe__container {
  position:absolute;
  top: 28%;
  left: 20px;
  height: 25px;
  overflow: hidden;
}
.back-btn:hover,.back-btn:active {
  background-color: #f0f2f4;
}

@media only screen and (max-width: 499px){
  .search-main__view {
    width: unset;
    height: 86px;
  }
  .game-search-button {
    width: 64px;
    height: 30px;
    padding: 5px;
  }
  .game-search-input {
    padding: 1px 15px;
  }
  .top-download {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .search-hint-view {
    top: 60px;
  }
  .search-container {
    /*height: unset;*/
  }
  .top-container {
    /*height: unset;*/
    padding: 0 15px;
    left: 0;
    right: 0;
  }
  .swipe__container {
    top: 20%;
  }
  .back-btn,.back-btn img {
    width: 32px;
    height: 32px;
  }
  
}

</style>
<template>
  <div class="sdk-page">
    <div class="sdk-nav-bar">
      <div @click="back" style="width: 40px;height: 40px;display: flex;align-items: center;justify-content: center; position: absolute;">
        <van-icon name="arrow-left" size="20" color="#888888"/>
      </div>
      <div style="margin-left:40px;font-size: 16px;">
        礼包领取
      </div>
      <van-icon @click="close" name="cross" color="#d0d0d0" size="20" class="close-x"/>
    </div>
    <div style="margin: 10px 20px;height: calc(100vh - 60px);overflow: hidden;overflow-y: auto;">
      <div style="display: flex;flex-direction: column;">
        <div class="game-info-view" style="height: 60px;">
          <img :src="imgUrl" width="40" height="40"/>
          <div style="display: flex;flex-direction: column;align-items: flex-start;justify-content: space-around;margin-left: 10px">
            <span style="margin-left: 5px;font-size: 15px;">{{ gameName }}</span>
            <div style="font-size: 14px;">
              <span class="game-tag" v-for="tag in tagList">{{tag}}</span>
            </div>
          </div>
        </div>
        
        <div class="game-info-view" @click="toGiftRecord" style="height: 40px;margin-top:10px;justify-content: space-between;font-size: 13px;">
          <div>礼包领取记录</div>
          <van-icon name="arrow" color="#888888" />
        </div>

        <div style="margin-top: 10px;">
          <div v-if="list.length !== 0" style="display: flex;flex-direction: column;">
            <div class="game-menu" v-for="(item,index) in list" style="min-height: 50px;">
              <div @click="open(index)" style="display: flex;align-items: center;justify-content: space-between;padding: 6px 0;width: 90%;margin: 0 auto;font-size: 12px;">
                <div style="flex: 1;">
                  <span style="font-size: 12px;font-weight: bold;">{{item.giftName}}</span>
                  <div v-if="item.giftType !== '3'" :style="{color: formatterColor(item.percentage)}" style="text-align: left;padding: 0 0 6px;width: 90%;font-size: 12px;">
                    <div>
                      <span>剩余：{{ item.percentage }}%</span>
                    </div>
                  </div>
                </div>
                
                <div style="display: flex;align-items: center">
                    <van-button v-if="item.status === -1" size="mini" round color="#d5d5d5" class="get-button">
                        <span class="get-button-text">查看说明</span>
                    </van-button>
                  <van-button v-if="item.status === 0 && (item.percentage && item.percentage !== 0)"
                              @click.stop="getGift(item.giftId)" size="mini" round color="#3DA7FF" class="get-button">
                    <span class="get-button-text">领取</span>
                  </van-button>
                    <van-button v-if="item.status === 1"
                                @click.stop="copyGiftCode(item.giftCode)" size="mini" round color="#e9b566" class="get-button">
                        <span class="get-button-text">复制</span>
                    </van-button>
                  <van-icon v-show="!item.open" name="arrow-down" color="#888888" />
                  <van-icon v-show="item.open" name="arrow-up" color="#888888" />
                </div>
              </div>
              <div v-show="item.open" style="width: 85%;margin: 10px auto;text-align: left;font-size: 12px;color: #666666">
                <div class="game-detail-info">
                  <span style="color: #48c9b0;font-size: 13px;">礼包内容</span>
                </div>
                <span style="margin: 20px 10px 0;display: block">
                  {{item.giftDetail || '无'}}
                </span>
                <div class="game-detail-info" style="margin-top: 15px;">
                  <span style="color: #48c9b0;font-size: 13px;">领取限制</span>
                </div>
                <span style="margin: 20px 10px 0;display: block">
                  {{formatterLimit(item.giftLimit) || '无'}}
                </span>
                <div class="game-detail-info" style="margin-top: 15px;">
                  <span style="color: #48c9b0;font-size: 13px;">礼包说明</span>
                </div>
                <span style="margin: 20px 10px 0;display: block;">
                  {{item.giftDesc || '无'}}
                </span>

<!--                <div style="margin-top: 15px;" v-if="item.giftType === '3'">-->
<!--                  <span style="color: #3DA7FF;font-size: 13px;">点击联系客服</span>-->
<!--                </div>-->
              </div>
            </div>
          </div>
          <div v-else>
            <div style="text-align: center;margin-top: 20px;color: #888888;font-size: 13px;">
              暂无礼包
            </div>
          </div>
        </div>

        <myloading v-show="showLoading"></myloading>
      </div>
    </div>
  </div>
</template>

<script>
import sdkNotice from "@/api/sdk-notice";
import {receiveGift, request} from "@/api/request";
import Myloading from "@/components/myloading.vue";

export default {
  name: "SdkGift",
  components: {Myloading},
  data(){
    return {
      showLoading: false,
      list: '',
      imgUrl: '',
      gameName: '',
      tagList: [],
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    back() {
      this.$router.back()
    },
    close(){
      sdkNotice.close();
    },
    getData() {
      this.showLoading = true;
      let options = {
        url:"/sdk/h5/getGameGiftList",
        data: {
          gameId: localStorage.getItem("gameId")
        }
      };
      request(options).then(res => {
        this.showLoading = false;
        if (res.data.success) {
          let d = res.data.data;
          this.imgUrl = d.imgUrl;
          this.gameName = d.gameName;
          this.tagList = d.tagList;
          d.giftList.forEach(function(e,v) {
            e.open = false;
          });
          this.list = d.giftList;
        }
        else {
          this.$toast(res.data.error.message);
        }
      });
    },
    getGift(giftId){
      receiveGift(giftId).then(res => {
        if(res.data.success){
          let code = res.data.data.giftCode;
          this.$dialog.alert({
            title: '礼包码',
            message: '你的礼包码为' + code,
            showCancelButton: false,
            confirmButtonText: '复制'
          }).then(() => {
            sdkNotice.copy(code);
            this.getData();
          })
        }else {
          this.$toast.fail(res.data.error.message);
        }
      })
    },
    toGiftRecord(){
      this.$router.push("/sdk/giftRecord")
    },
    open(index) {
      this.list[index].open = !this.list[index].open;
    },
    copyCode(v) {
      this.$dialog.alert({
        title: '礼包码',
        message: '你的礼包码为' + v,
        showCancelButton: false,
        confirmButtonText: '复制'
      }).then(() => {
        const input = document.createElement('input')
        document.body.appendChild(input)
        input.setAttribute('value', v)
        input.select()
        if (document.execCommand('copy')) {
          document.execCommand('copy')
        }
        document.body.removeChild(input)
      })
    },
    copyGiftCode(v) {
      sdkNotice.copy(v);
      this.$toast("复制成功");
    },
    formatterColor(percentage){
      let color = "";
      if(percentage < 30){
        color = "#F56C6C";
      }else if(percentage < 70){
        color = "#E6A23C";
      }else {
        color = "#67C23A";
      }
      return color;
    },
    formatterLimit(limit){
      let rep = "";
      if(limit === "1"){
        rep = "一个账号只能领取一次";
      }else if(limit === "2"){
        rep = "一个角色只能领取一次";
      }
      return rep;
    }
  }
}
</script>

<style scoped>
.game-info-view {
  display: flex;
  align-items: center;
  padding:0 10px;
  background-color: #f0f2f4;
  border-radius: 5px;
}
.get-button {
  padding: 0 8px;
  margin-right: 15px;
}
.game-menu{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: #f0f2f4;
  border-radius: 4px;
  margin-top: 6px;
  align-items: flex-start;
}
.game-menu:first-child {
  margin-top: 0;
}
.game-detail-info{
  /*background-image: url(../../img/gift/title_background.png);*/
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 60px;
  height: 10px;
  padding-top: 5px;
  text-align: center;
}
.game-tag {
  font-size: 10px;
  border-radius: 3px;
  margin-right: 5px;
  /*border: 1px solid #e6a23c;*/
  padding: 2px 3px;
  color: #e6a23c;
}
</style>
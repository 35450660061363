<template>
  <div>
    <div ref="topView">
      <div style="height: 40px;" ref="navBox">
        <div ref="topNav" id="topNav" class="top-nav" @mousedown.stop="mouseDownHandle" style="overflow: hidden;overflow-x: auto;">
          <div v-for="(item,index) in topicList"
               :key="item.typeId"
               class="top-nav-text"
               @click="moveLine(index,item.typeId)">
            {{ item.typeName }}
          </div>
          <span class="top-nav-slide-line" style="width: 50px;pointer-events: auto;" :style="{transform: underlineX}"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "topNav",
  props: {
    active: {
      type: Number,
      default: 0
    },
    topicList: {
      type: Array,
      default: () => [],
    }
  },
  data(){
    return {
      underlineX: "translate3d(calc(-50% + 30px), 0px, 0px)",
      topics: this.topicList,
      currentIndex: this.active,
    }
  },
  mounted() {
    if(this.topicList.length !== 0){
      this.moveLine(this.currentIndex,this.topicList[this.currentIndex].typeId)
    }
    setTimeout(() => {
      const box = this.$refs.navBox;
      const view = this.$refs.topView;
      const topNav = this.$refs.topNav;
      box.style.width = topNav.style.width = view.clientWidth + "px";
      window.addEventListener('resize',() => {
        box.style.width = topNav.style.width  = view.clientWidth + "px";
      })
    },10)
  },
  methods: {
    moveLine(index,typeId){
      this.currentIndex = index;
      this.$emit("update:active",index);
      this.$emit('click',typeId);
      let defaultGap = 30;
      //item宽度60px
      let moveGap = 60;

      let style = "translate3d(calc(-50% + {1}px), 0px, 0px)";
      if(index === 0){
        this.underlineX = style.replace("{1}",defaultGap + "");
      }else {
        let gap = moveGap * index + defaultGap;
        this.underlineX = style.replace("{1}",gap + "");
      }

      // let nav = this.$refs.topNav;
      // if(index >= 6){
      //   nav.scrollTo({
      //     top: 0,
      //     left: 250,
      //     behavior: 'smooth'
      //   });
      // }else if(index <= 4){
      //   nav.scrollTo({
      //     top: 0,
      //     left: 0,
      //     behavior: 'smooth'
      //   });
      // }
    },
    mouseDownHandle(e){
      const mask = this.$refs.topNav;

      const startX = e.pageX - mask.offsetLeft;
      const scrollLeft = mask.scrollLeft;

      mask.onmousemove = (el) => {
        const ev = el || window.event;
        ev.preventDefault();
        let x = ev.pageX - mask.offsetLeft;
        let walk = (x - startX) * 2;
        if(walk !== 0){
          mask.scrollLeft = scrollLeft - walk;
        }
      };
      mask.onmouseup = () => {
        this.$refs.topNav.onmousemove = null;
        this.$refs.topNav.onmouseup = null;
      };
      mask.onmouseleave = () => {
        this.$refs.topNav.onmousemove = null;
        this.$refs.topNav.onmouseup = null;
        mask.onmouseleave = null;
      }
      if(e.preventDefault){
        e.preventDefault();
      }else {
        return false;
      }
    },
    onResize(){
      
    }
  }
}
</script>

<style scoped>
.top-nav::-webkit-scrollbar {
  display: none;
}
.top-nav {
  width: inherit;
  height: 40px;
  background-color: #fafcfd;

  display: flex;
  align-items: center;
  text-align: center;
  user-select: none;

  font-size: 14px;
  color: #252525;

  position: fixed;
  z-index: 99;
  scrollbar-width: none;
}
.top-nav-text {
  width: 50px;
  flex-basis: 60px; 
  flex-shrink: 0;
  cursor: pointer;
  transition: all .45s ease;
  margin-right: 0;
}
.top-nav-slide-line {
  position: absolute;
  bottom: 0;
  border-radius: 5px;
  background-color: #9ad1aa;
  height: 5px;
  width: 30px;
  -webkit-transform: translateX(-50%);
  transform: translate(-50%);
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
}

</style>
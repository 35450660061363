<template>
    <div class="main-page">
        <div style="width: 100%;height: 70px; background-color: #48c9b0">
            <div style="width: 100%; max-width: 300px; height: 70px; display: flex;align-items: center;margin: 0 auto;">
                <img src="../../static/logo/sw-logo.png" style="width: 40px;height: 40px;">
                <span style="font-size: 20px;font-weight: bold;margin-left: 26px;color: #ffffff">爽玩游戏</span>
            </div>
        </div>

        <div class="main-border">

            <van-form @submit="onSubmit" ref="submitForm">

                <div style="font-size: 18px;font-weight: bold;text-align: left;margin-bottom: 15px;">
                    <span>用户注册</span>
                </div>

                <van-field label="手机号码" label-width="60" style="color: #F18F25" placeholder="请输入手机号码"
                           class="border-input" v-model="account"
                           clearable>
                </van-field>

                <van-field label="登录密码" label-width="60" type="password" style="color: #F18F25;margin-top: 10px;"
                           placeholder="请输入登录密码" class="border-input"
                           v-model="password" clearable/>

                <van-field label="确认密码" label-width="60" type="password" style="color: #F18F25;margin-top: 10px;"
                           placeholder="请再次输入登录密码" class="border-input"
                           v-model="password2" clearable/>

                <van-field label="验证码" label-width="60" type="number" maxlength="6"
                           style="color: #F18F25;margin-top: 10px;" placeholder="验证码" class="border-input"
                           v-model="smsCode">

                    <template #button>
                        <van-count-down
                                v-show="showCountDown"
                                ref="countDown"
                                :time="60000"
                                :auto-start="false"
                                format="重新发送(ss秒)"
                                style="font-size: 12px;color: #888888"
                                @finish="finish"
                        />
                        <span style="color: #48c9b0;font-size: 12px;user-select: none;cursor: pointer;"
                              v-show="!showCountDown" @click="sendCode">发送验证码</span>
                    </template>
                </van-field>

                <div style="display: flex;justify-content: flex-start;align-items: center;margin-top: 10px;font-size: 12px;">
                    <van-checkbox v-model="agree" icon-size="13px" shape="square" checked-color="#48c9b0">
                        <span style="color: #333333;user-select: none;cursor: pointer;">我已阅读并同意</span>
                    </van-checkbox>
                    <span style="color: #007AFF;user-select: none;cursor: pointer;"
                          @click="openUserAgreement">《用户协议》</span>
                </div>

                <div class="box-button">
                    <span @click="back"
                          style="color: #48c9b0;user-select: none;cursor: pointer;font-size: 13px;">返回登录</span>
                    <van-button type="info" block native-type="submit" :loading="loading"
                                style="height: 35px;font-weight: bold;width: 150px"
                                loading-text="注册中"
                                color="#48c9b0">
                        注 册
                    </van-button>
                </div>
            </van-form>

            <van-popup v-model="showDialog" position="center" :lock-scroll="false" style="background-color: transparent"
                       :style="{ width: '90%', height: '70%' }">
                <div style="height: 100%;background-color: #FFFFFF;border-radius: 5px;overflow: hidden">
                    <div style="padding: 10px;height: calc(100% - 70px);">
                        <iframe v-show="true" allowfullscreen ref="dialogIframe" style="margin:0;padding:0"
                                :src="userAgreeUrl" frameborder="no" border="0"
                                marginwidth="0" marginheight="0" scrolling="yes" width="100%" height="100%"></iframe>
                    </div>
                    <div @click="showDialog = false"
                         style="height: 50px;display: flex;align-items: center;justify-content: center;border-top: 0.5px solid #DDDDDD">
                        <span style="font-size: 13px;color: #5A6EFC">关闭</span>
                    </div>
                </div>
            </van-popup>

        </div>

    </div>
</template>

<script>

    import {request} from '@/api/request'
    import crypto from '@/utils/crypto.js';

    import {Toast, Dialog} from 'vant'

    export default {
        name: 'gameRegister',
        data() {
            return {
                account: '',
                password: '',
                password2: '',
                smsCode: '',
                showCountDown: false,
                agree: false,
                accountPattern: /^1[0-9]{10}$/,
                passwordPattern: /^(?=.*\d)(?=.*[a-zA-Z]).{6,20}$/,
                showDialog: false,
                loading: false,
                channelId: '',
                gameId: '',
                from: '',
                userAgreeUrl: 'https://gw.daoyigame.com/static/res/agreement.html',
            };
        },
        mounted() {
            this.channelId = localStorage.getItem('channelId');
            this.gameId = localStorage.getItem('gameId');


        },
        methods: {
            back() {
                this.$router.back();
            },
            openUserAgreement() {
                //this.showDialog = true;
                window.open(this.userAgreeUrl, '');
            },
            finish() {
                this.showCountDown = false;
                this.$refs.countDown.reset();
            },
            sendCode() {
                let ok = this.checkUserName();
                if (ok !== 'ok') {
                    Toast(ok);
                    return;
                }
                this.showLoading = true;
                let options = {
                    url: "/h5/sendSms",
                    data: {
                        type: 1,
                        phone: this.account
                    }
                };
                request(options).then(res => {
                    console.log(res);
                    this.showLoading = false;
                    if (res.data.success) {
                        this.showCountDown = true;
                        this.$refs.countDown.start();
                        Toast('验证码发送成功');
                    } else {
                        Toast(res.data.error.message);
                    }
                });
            },
            onSubmit() {
                let ok = this.checkForm();
                if (ok !== 'ok') {
                    Toast(ok);
                    return;
                }
                this.loading = true;
                let options = {
                    url: "/h5/register",
                    data: {
                        gameId: this.gameId,
                        username: this.account,
                        password: crypto.encrypt(this.password),
                        smsCode: this.smsCode,
                        channelId: this.channelId,
                    }
                };
                request(options).then(res => {
                    this.loading = false;
                    if (res.data.success) {
                        Dialog.alert({
                            title: '提示',
                            message: '用户注册成功',
                            messageAlign: 'center'
                        }).then(() => {
                            this.back();
                        });
                    } else {
                        Dialog.alert({
                            title: '提示',
                            message: res.data.error.message,
                            messageAlign: 'center'
                        }).then(() => {
                        });
                    }
                });
            },
            checkUserName() {
                if (!this.account) {
                    return "请输入手机号码"
                }
                if (!this.accountPattern.test(this.account)) {
                    return "手机号码格式错误";
                }
                return 'ok';
            },
            checkForm() {
                if (!this.account) {
                    return "请输入手机号码"
                }
                if (!this.password) {
                    return "请输入密码";
                }
                if (!this.password2) {
                    return "请再次输入密码";
                }
                if (!this.smsCode) {
                    return "请输入手机验证码";
                }
                if (!this.accountPattern.test(this.account)) {
                    return "手机号码格式错误";
                }
                if (!this.passwordPattern.test(this.password)) {
                    return "密码长度为6到20且同时包含数字字母";
                }
                if (this.password !== this.password2) {
                    return "两次密码输入不一致，请重新输入";
                }
                if (!this.agree) {
                    return '帐号注册需要同意用户协议';
                }
                return 'ok';
            },
        }
    }
</script>

<style scoped>
    .main-page {
        width: 100%;
        height: 100vh;
        overflow: hidden;
        background-color: #f8f8f8;
    }

    .main-border {
        max-width: 300px;
        text-align: center;
        border-radius: 0.3rem;
        background-color: #ffffff;
        width: 84%;
        border: 1px solid #eceaea;
        padding: 10px 20px;
        box-sizing: border-box;
        margin: 90px auto 0;
        box-shadow: 2px 2px 10px 3px rgba(0, 0, 0, .1);
    }

    .box-button {
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .van-field {
        outline: 1px #dbdbdb solid;
        border-radius: 3px;
    }

    .van-field:focus-within {
        outline: 2px #48c9b0 solid;
    }

</style>

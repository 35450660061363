<template>
    <div class="main-container">
        <div>
            <div class="top-info">
                <img src="../assets/default_header_img.png" style="width: 40px;height: 40px;border-radius: 10px;">

                <div class="title-user-panel">
                    <div>
                        <span class="user-name">{{username}}</span>
                        <span class="user-uid">{{accountName}}<span style="margin-left: 10px;">id: {{accountId}}</span></span>
                    </div>
                    <div class="second-info">
                        <span>星元:  <span style="font-weight: bold">{{ptb}}</span></span>
                        <span style="margin-left: 15px;">代金券:  <span style="font-weight: bold">{{djq}}</span></span>
                    </div>
                </div>
            </div>

            <van-divider :style="{ borderColor: '#d0d0d0', 'font-size': '.8rem', margin: '0 10px' }">
            </van-divider>
            <div class="main-panel">

                <van-cell title="我的实名" :value="realNameInfo" />
                <van-cell title="修改密码" @click="goPasswordPage" is-link />
                <van-cell title="联系客服" @click="goKfPage" is-link />
                <van-cell title="小号管理" @click="goAccountPage" is-link />
                <van-cell title="星元充值" v-if="false" @click="goRechargePage" is-link />
                <van-cell title="福利码兑换" v-if="false" is-link />
                <div></div>
            </div>
        </div>


        <div class="main-bottom-button">
            <div @click="logout" class="main-button" style="color: #888888;background: #ededed;margin-right: 15px;">
                退出登录
            </div>
            <div @click="continuePlay" class="main-button">返回游戏</div>
        </div>

    </div>
</template>

<script>
    import {request} from '@/api/request'
    import headImg from '@/assets/default_header_img.png'
    import {Dialog, Toast} from 'vant';

    export default {
        name: "MainMenu",
        data () {
            return {
                headImg,
                username: '',
                accountName: '',
                accountId: '',
                ptb: '0.0',
                djq: '0.0',
                gameId: '',
                exchangeCode: '',
                showJsButton: false,
                userInfo: {},
                realNameInfo: '未实名',
            }
        },
        mounted() {
            this.gameId = localStorage.getItem('gameId');
            this.getLoginInfo();
        },
        methods: {
            continuePlay() {
                this.$parent.$parent.close('mainMenu');
            },
            getLoginInfo() {
                let options = {
                    url:"/h5/getLoginInfo",
                    data: {
                        gameId: this.gameId
                    }
                };
                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        let data = res.data.data;
                        this.username = data.userName;
                        this.accountName = data.accountName;
                        this.accountId = data.accountId;
                        this.realNameInfo = data.realNameInfo && data.realNameInfo;
                        this.ptb = data.ptb;
                        this.djq = data.djq;
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            logout() {
                Dialog.confirm({
                    message: '确认退出登录吗？',
                }).then(() => {
                    let options = {
                        url:"/h5/logout",
                        data: {}
                    };
                    request(options).then(res => {
                        if (res.data.success) {
                            localStorage.removeItem('token');
                            localStorage.removeItem('user');
                            localStorage.removeItem('accountId');
                            window.location.reload();
                        }
                        else {
                            Toast(res.data.error.message);
                        }
                    });
                }).catch(() => {
                });
            },
            goPasswordPage() {
                this.$parent.$parent.show('password')
            },
            goKfPage() {
                this.$parent.$parent.show('kf')
            },
            goAccountPage() {
                this.$parent.$parent.show('account')
            },
            goRechargePage() {
            },

        }
    }
</script>

<style scoped>
    .main-container {
        width: 100%;
        height: 300px;
        box-sizing: border-box;
        overflow: hidden;
        padding: 0 5px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .top-info {
        height: 60px;
        text-align: left;
        display: flex;
        padding: 0 15px;
        align-items: center;
        justify-content: left;
        position: relative;
    }

    .close-x {
        position: absolute;
        right: 7px;
        top: 5px;
    }

    .title-user-panel {
        display: inline-block;
        margin-left: .8rem;
        width: 100%;
    }

    .user-name {
        font-size: 12px;
        color: #4d4e50;
        font-weight: bold;
    }

    .user-uid {
        font-size: 12px;
        color: #7a7a7c;
        margin-left: 15px;
    }

    .second-info {
        margin-top: 5px;
        font-size: 12px;
        color: #888888;
    }

    .main-panel {
        text-align: left;
        width: 100%;
        overflow-y: auto;
    }

    .van-cell {
        font-size: .8rem;
    }

    .main-button {
        background: #F46C49;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        font-size: 12px;
        width: 75px;
        font-weight: bold;
        border-radius: 3px;
        cursor: pointer;
        user-select: none;
    }

    .main-bottom-button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
    }

    .van-cell {
        padding: 5px 16px;
    }

    .van-field {
        outline: 1px #979696 solid;
        border-radius: 3px;
    }

    /*.van-field:focus-within{
        outline: 1px #3478F6 solid;
    }*/
    /deep/ .van-field__control {
    //color: #3478F6;
    }
</style>
<template>
  <div>
    <div style="margin-top: 10px;">
      <div @click="toGameDetails(item.gameId)" class="sidebar-right-game-item" style="padding:10px 0;border-bottom: 1px solid #f0f2f4;" v-for="(item,index) in hotList" :key="index">
        <div>
          <img class="game__icon" :src="item.icon" alt=""/>
        </div>
        <div>
          <div class="game__title">{{ item.gameName }}</div>
          <div v-if="!!item.tag">
            <span class="sidebar-right-game-item-tag" v-for="(tag,index) in item.tag.split(',')" :key="tag">{{ tag }}</span>
          </div>
          <div class="sidebar-right-game-item-tag" v-if="false">
            <span style="color: #ed7840;">今天 13:00</span>
            <span style="margin-left: 5px;">开服</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getHotGame} from "@/api/request";

export default {
  name: "hotGame",
  data(){
    return {
      hotList: [],
    }
  },
  mounted() {
    this.getHot();
  },
  methods: {
    toGameDetails(gameId){
      this.$router.push(`/game/details/${gameId}`);
    },
    getHot(){
      getHotGame().then(res => {
        if(res.data.success){
          this.hotList = res.data.data;
        }
      })
    },
  }
}
</script>

<style scoped>
.game__icon {
  width: 80px;
  height: 80px;
}


@media only screen and (max-width: 499px) {
  .game__icon {
    width: 60px;
    height: 60px;
  }
}
</style>
<template>
  <div style="position:relative;">
    <swiper-container
        ref="swiperContainer"
        class="swiper-container"
        slides-per-view="auto"
        mousewheel-force-to-axis="true"
        navigation="false"
        pagination="false"
        :space-between="spaceBetween"
        :style="{maxWidth: maxWidth + 'px'}"
    >
      <swiper-slide class="swiper-slide" :style="{maxWidth: maxWidth + 'px'}">
        <video :src="videoUrl" 
               :poster="poster"
               controls muted disablePictureInPicture
               controlslist="nodownload nofullscreen noremoteplayback noplaybackrate"
               class="media"></video>
      </swiper-slide>
      <swiper-slide v-for="(item,index) in imageList" :key="index" class="swiper-slide" :style="{maxWidth: (maxWidth / 3.17) + 'px'}">
        <img :src="item" class="media"/>
      </swiper-slide>
    </swiper-container>
    <div class="publicity-view" v-if="!!videoUrl || imageList.length !== 0">
      <div @click="onClickPublicity(0)" v-if="!!videoUrl" class="publicity-item" :class="{'publicity-item__active': type === 0}">宣传片</div>
      <div @click="onClickPublicity(1)" v-if="imageList.length !== 0" class="publicity-item" :class="{'publicity-item__active': type === 1}">宣传图</div>
    </div>
  </div>
</template>

<script>
import { register } from "swiper/element";
register();

export default {
  name: "Swiper",
  props: {
    spaceBetween: {
      type: Number,
      default: 10
    },
    poster: {
      type: String,
      default: ''
    },
    videoUrl: {
      type: String,
      default: ''
    },
    imageList: {
      type: Array,
      default: () => []
    },
    maxWidth: {
      type: Number,
      default: 540
    }
  },
  data(){
    return {
      type: 0,
    }
  },
  watch: {
    videoUrl(){
      if(!this.videoUrl){
        this.type = 1;
      }
    },
    imageList(){
      if(this.imageList.length === 0){
        this.type = 0;
      }
    }
  },
  mounted() {
    let swiper1 = this.$refs.swiperContainer.swiper;
    swiper1.on('slideChangeTransitionEnd', () => {
      if(swiper1.activeIndex === 0){
        this.type = 0;
      }else {
        this.type = 1;
      }
    })
  },
  methods: {
    onClickPublicity(type){
      this.type = type;
      let index = this.type === 0 ? 0 : 1;
      this.$refs.swiperContainer.swiper.slideTo(index,500,false);
    }
  }
}
</script>

<style scoped>
.swiper-slide {
  width: 100% !important;
  aspect-ratio: 16 / 9;
}
.swiper-slide:not(:first-child) {
  width: 31.5% !important;
  aspect-ratio: 9 / 16;
}

.media {
  width:100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
}

.publicity-view {
  /*width: 110px;*/
  /*padding: 5px 10px;*/
  padding: 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  bottom: 40px;
  left: calc(50% - 55px);
  z-index: 2;
  background-color: rgba(0,0,0,0.6);
  border-radius: 99px;
  cursor: pointer;
}
.publicity-item {
  padding: 2px 8px;
  color: #fff;
  border-radius: 99px;
  font-weight: 700;
  font-size: 14px;
}
.publicity-item__active {
  color: #252525;
  background-color: #fff;
}

@media only screen and (max-width: 499px){
  .publicity-item {
    font-size: 12px;
  }
}

</style>
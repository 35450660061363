<template>
  <div>
    <div>
      <van-nav-bar left-arrow z-index="99" placeholder safe-area-inset-top @click-left="$router.push('/my')" />
    </div>
    <div>
      <div>
        <div class="user-info-view" v-if="username">
          <div class="base-flex-row" style="align-items: center;">
            <div>
              <img src="../../../static/icon/user.png" width="60" height="60" alt="" style="border-radius: 99px;" />
            </div>
            <div>
              <div style="font-size: 12px;color: #75787b;margin-left: 15px;">
                <span>账号：</span>
                <span>{{ username }}</span>
              </div>
            </div>
            <div v-if="false">
              <van-icon name="edit" size="16" color="#75787b"/>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="setting-view" v-for="v in itemList">
          <router-link :to="c.path" @click.native="c.click()" class="base-flex-row setting__container-item" v-for="c in v.item" :key="c.label">
            <div style="font-size: 14px;">{{ c.label }}</div>
            <div>
              <van-icon name="arrow" size="14" color="#75787b"/>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {logout} from "@/api/request";

export default {
  name: "setting",
  data(){
    return {
      itemList: [
        {item: [
            {label: "账号安全",path: '/security/page',click: () => {}}, 
            {label: "关于",path: '/introduce',click: () => {}},
          ]
        },
        {
          item: [
            {label: "退出登录",path: '',click: () => this.logout()},
          ]
        }
      ],
      username: ''
    }
  },
  mounted() {
    this.checkUser();
    this.addItem();
  },
  methods: {
    logout(){
      if(!this.username) {
        this.$toast.fail('请先登录')
        return;
      }
      this.$dialog.confirm({
          message: '确认要退出登录吗?',
          theme: 'round-button',
          confirmButtonColor: '#9bd2ac',
          cancelButtonColor: '#c4c4c4'
      }).then(() => {
          logout().then(res => {
            localStorage.removeItem("web_token");
            localStorage.removeItem("user_info");
            this.$router.push('/home');
          })
      })
    },
    checkUser(){
      let userinfo = JSON.parse(localStorage.getItem("user_info"));
      if(!userinfo){
        return;
      }
      this.username = userinfo.userName;
    },
    addItem(){
      if(!this.username){
        this.itemList = [
          {item: [
              {label: "关于",path: '/introduce'},
            ]
          }
        ]
      }
    },
  },
}
</script>
<style scoped src="../../../static/css/setting.css"></style>
<style scoped>
/deep/ .van-nav-bar__left{
  padding-left: 8px;
}
.setting-view {
  width: 90%;
}
.setting__container-item {
  color: #252525;
  margin-top: 20px;
}

.setting__container-item:first-child {
  margin-top: 0;
}
</style>
<template>
  <div class="user-agreement-view">
    <van-nav-bar
        title="隐私政策"
        left-arrow
        left-text="返回"
        @click-left="onClickLeft"
        placeholder
        safe-area-inset-top/>
    <div style="height: 100%;">
      <iframe ref="iframe" src="https://game.sw007.cn/static/agreement/privacy.html" scrolling="auto" frameBorder="0" class="user-iframe"></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: "privacy",
  mounted() {
  },
  methods: {
    onClickLeft() {
      this.$router.back();
    },
  }
}
</script>

<style scoped src="../../../../static/css/setting.css"></style>
<style scoped>
/deep/ .van-nav-bar__left{
  padding-left: 8px;
}
.user-agreement-view {
  max-width: 500px;
}
.user-iframe{
  width:100%;
  height: 100vh;
  position:relative;
  top: 0;
  left: 0;
}

@media only screen and (max-width: 499px){
  .user-agreement-view {
    max-width: 100%;
  }
}
</style>
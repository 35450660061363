<template>
  <div>
    <div class="c-container">
      <div class="c-login-head">
        <div class="flex flex-row flex-1 c-login-head__container">
          <div class="c-login-head__title">登录</div>
          <div>
            <van-icon name="cross" class="pointer" :color="iconColor" @mouseenter="setIconColor(0.7)" @mouseleave="setIconColor(1)" @click="close"/>
          </div>
        </div>
      </div>
      
      <div class="c-login-body">
        <div class="flex flex-column">
          <div>
            <input v-model.trim="loginParam.username" class="c-login-body__item-input" type="text" placeholder="请输入手机号" />
          </div>
          <div>
            <input v-model.trim="loginParam.password" class="c-login-body__item-input" type="password" placeholder="请输入密码" />
          </div>
          <div class="mt-15">
            <div class="flex" style="justify-content: space-between;align-items: center;">
              <div class="c-login-body__text-button" @click="toForget">忘记密码</div>
              <div class="c-login-body__text-button" @click="toRegister">立即注册</div>
            </div>
          </div>
          <div style="margin-top: 30px;">
            <div>
              <van-checkbox style="width: 100%;"
                            v-model="consent"
                            checked-color="#9ad1aa"
                            icon-size="16px">
                <div style="font-size: 14px;color: #b8bec4;">
                  勾选即表示同意
                  <span @click="agreement(1)" style="color: #90e1accc">《用户协议》</span>
                  &
                  <span @click="agreement(2)" style="color: #90e1accc">《隐私政策》</span>
                </div>
              </van-checkbox>
            </div>
          </div>
          <div class="mt-15">
            <van-button @click="checkConsent" block color="#90e1ac" style="border-radius: 5px;">登录</van-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tools from "@/utils/Tools";
import Crypto from "@/utils/crypto";
import {login} from "@/api/request";
import {StorageEmitter} from "@/utils/StorageEmitter";
import {Toast} from "vant";
import {StorageEmitterType} from "@/utils/StorageEmitterType";

export default {
  name: "login",
  data(){
    return {
      iconColor: 'rgba(13, 13, 13, 1)',
      consent: false,
      loginParam: {
        username: '',
        password: ''
      },
    }
  },
  methods: {
    close(){
      this.$parent.close();
    },
    toRegister(){
      this.$parent.$parent.openRegister();
    },
    toForget(){
      this.$parent.$parent.openForget();
    },
    setIconColor(alpha){
      this.iconColor = `rgba(13, 13, 13, ${alpha})`;
    },
    checkConsent(){
      if(this.consent){
        this.doLogin();
      }else {
        this.$dialog.alert({
          message: '点击确认按钮，表示已阅读并同意《用户协议》&《隐私政策》'
        }).then(() => {
          this.consent = true;
          this.doLogin();
        })
      }
    },
    doLogin(){
      if(!Tools.isPhone(this.loginParam.username)){
        this.$toast.fail('手机号码无效，请正确填写手机号码')
      }else if(!Tools.isPassWord(this.loginParam.password)){
        this.$toast.fail("密码为6-12位数字、字母、字符")
      }else if(!this.consent){
        this.$toast.fail("为了更好地保障你的合法权益，进入下一步前，请阅读并同意 《用户协议》&《隐私政策》")
      }else {
        let params = {
          username: this.loginParam.username,
          password: Crypto.encrypt(this.loginParam.password)
        }
        login(params).then(res => {
          if(res.data.success){
            localStorage.setItem("web_token",res.data.data.token);
            localStorage.setItem("user_info",JSON.stringify(res.data.data));
            
            let detailLogin = sessionStorage.getItem(StorageEmitterType.DETAILS_LOGIN);
            sessionStorage.removeItem(StorageEmitterType.DETAILS_LOGIN);
            if(!detailLogin){
              StorageEmitter.emit("login","1");
            }else {
              StorageEmitter.emit(StorageEmitterType.DETAILS_LOGIN,"1");
            }
            
            this.loginParam = {
              username: '',
              password: ''
            }
            this.close();
          }else {
            // this.$toast(res.data.error.message);
            Toast(res.data.error.message)
          }
        })
      }
    },
    agreement(type){
      if(type === 1){
        this.$router.push('/user/agreement');
      }else if(type === 2){
        this.$router.push('/privacy');
      }
      this.close();
    }
  }
}
</script>

<style src="../../static/css/network-auth.css"></style>
<style scoped>
</style>
<template>
  <div>
    <div style="padding-bottom: 20px;">
      <div style="height: 60px;">
        <div class="top-title" style="position: fixed;z-index: 201;">
          <div class="middle-size" style="display: flex;flex-direction: row;position: fixed;background-color: #fafcfd;padding: 10px 0 5px;">
            <div class="game-detail-back">
              <img src="../../../static/icon/back.png" @click="$router.back()">
            </div>
            <div style="margin-left: 10px;">
              游戏礼包
            </div>
          </div>
        </div>
      </div>
      <div>
        <van-collapse @change="onChangeCollapse" v-model="activeName" accordion v-if="giftList.length !== 0">
          <van-collapse-item :ref="'collapseItem' + index" :name="index + ''" v-for="(item,index) in giftList" :key="item.giftId">
            <template #title>
              <div>
                <span style="font-size: 17px;">{{item.giftName}}</span>
                <div v-if="item.giftType !== '3'" :style="{color: formatterColor(item.percentage)}">剩余{{ item.percentage }}%</div>
              </div>
            </template>
            <template #value>
              <div style="margin-right: 10px;">
                <button @click.stop="onClick(item.giftId)" class="get-gift" v-if="item.giftType !== '3'">领取</button>
              </div>
            </template>
            <div>
              <div style="display: flex;flex-direction: column;">
                <div>
                  <div class="gift__content-view-title">礼包内容</div>
                  <div class="p-x_15 gift__content-view-main">
                    {{ item.giftDetail || '暂无礼包内容'}}
                  </div>
                </div>
                <div>
                  <div class="gift__content-view-title">领取限制</div>
                  <div class="p-x_15 gift__content-view-main">
                    {{ formatterLimit(item.giftLimit) || '暂无'}}
                  </div>
                </div>
                <div>
                  <div class="gift__content-view-title">礼包描述</div>
                  <div class="p-x_15 gift__content-view-main">
                    {{ item.giftDesc || '暂无描述'}}
                  </div>
                </div>
                <div>
                  <div class="gift__content-view-title">截止日期</div>
                  <div class="p-x_15 gift__content-view-main">
                    {{ item.endTime || '暂无截至日期'}}
                  </div>
                </div>
              </div>
            </div>
          </van-collapse-item>
        </van-collapse>
        <div v-else>
          <div>
            <img src="../../../static/icon/empty.png" width="350" height="300" style="margin: 0 auto;display: block;"/>
          </div>
          <div class="no-gift-text">
            暂无礼包
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {queryGift, receiveGift} from "@/api/request";

export default {
  name: "giftlist",
  data(){
    return {
      activeName: "0",
      gameId: "",
      giftList: [],
    }
  },
  mounted() {
    this.gameId = this.$route.params.pathMatch;
    this.loadGift();
  },
  methods: {
    loadGift(){
      queryGift(this.gameId).then(res => {
        if(res.data.success){
          this.giftList = res.data.data;
          if(this.giftList.length !== 0){
            this.onChangeCollapse(this.activeName);
          }
        }
      })
    },
    onClick(giftId){
      const token = localStorage.getItem("web_token");
      if(!token){
        this.$router.push('/login');
        return;
      }
      receiveGift(giftId).then(res => {
        if(res.data.success){
          this.copyCode(res.data.data.giftCode);
        }else {
          this.$toast.fail(res.data.error.message);
        }
      })
    },
    copyCode(v) {
      this.$dialog.alert({
        title: '礼包码',
        message: '你的礼包码为' + v,
        showCancelButton: false,
        confirmButtonText: '复制'
      }).then(() => {
        const input = document.createElement('input')
        document.body.appendChild(input)
        input.setAttribute('value', v)
        input.select()
        if (document.execCommand('copy')) {
          document.execCommand('copy')
        }
        document.body.removeChild(input)
      })
    },
    onChangeCollapse(activeName){
      if(!activeName){
        this.setCollapseItem(this.activeName,"8px");
        return;
      }
      if(this.activeName){
        this.setCollapseItem(this.activeName,"8px");
      }
      this.setCollapseItem(activeName,"0");
    },
    setCollapseItem(activeName,px){
      let _ref = this.$refs["collapseItem" + activeName][0];
      _ref.$el.children[0].style.borderBottomLeftRadius = px;
      _ref.$el.children[0].style.borderBottomRightRadius = px;
    },
    formatterColor(percentage){
      let color = "";
      if(percentage < 30){
        color = "#F56C6C";
      }else if(percentage < 70){
        color = "#E6A23C";
      }else {
        color = "#67C23A";
      }
      return color;
    },
    formatterLimit(limit){
      let rep = "";
      if(limit === "1"){
        rep = "一个账号只能领取一次";
      }else if(limit === "2"){
        rep = "一个角色只能领取一次";
      }
      return rep;
    }
  }
}
</script>

<style scoped src="../../../static/css/game-detail.css" />
<style scoped src="../../../static/css/gift.css" />
<style scoped>
::v-deep .van-cell {
  background-color: #f0f2f4;
  height: 80px;
  align-items: center;
  margin-top: 10px;
  border-radius: 8px;
}
::v-deep .van-collapse-item__content {
  background-color: #f0f2f4;
}
::v-deep .van-collapse-item__wrapper {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.p-x_15 {
  padding: 0 15px;
}
.gift__content-view-title {
  color: #252525;
  margin-top: 10px;
}
.gift__content-view-main:first-child {
  margin-top: 0;
}
.gift__content-view-main {
  color: #75787b;
}
.no-gift-text {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}
</style>
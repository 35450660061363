<template>
    <div class="sdk-page">
        <div class="sdk-nav-bar">
            <div @click="back" style="width: 40px;height: 40px;display: flex;align-items: center;justify-content: center; position: absolute;">
                <van-icon name="arrow-left" size="20" color="#888888"/>
            </div>
            <div style="margin-left:40px;font-size: 16px;">
                联系客服
            </div>
            <van-icon @click="close" name="cross" color="#d0d0d0" size="20" class="close-x"/>
        </div>

        <div class="sdk-page-body">
            <div class="kf-item">
                <img src="../../assets/qq.png" style="width: 25px;height: 25px;border-radius: 3px;">
                <div style="flex: 1; margin-left: 10px;text-align: left">qq客服：2982250830</div>
                <div class="sec-button" @click="copy">复制</div>
            </div>
            <!--<div class="kf-item">
                <img src="../../assets/wx.png" style="width: 25px;height: 25px;border-radius: 3px;">
                <div style="flex: 1; margin-left: 10px;text-align: left">微信客服：wxkf123456</div>
                <div class="sec-button">复制</div>
            </div>-->

            <div style="margin-top: 15px;display: flex;flex-direction: column;align-items: center">
                <img src="../../static/logo/qili-qrcode.png" style="width: 90px;height: 90px;"/>
                <span style="margin-top: 10px;font-size: 14px;color: #333">扫码关注更多精彩活动</span>
            </div>
        </div>



    </div>
</template>

<script>
    import '../../static/css/sdkcommon.css';
    import sdkNotice from "@/api/sdk-notice";

    export default {
        name: "SdkKf",
        data: function () {
            return {
            }
        },
        mounted() {
        },
        methods: {
            back() {
                this.$router.back();
            },
            close() {
                sdkNotice.close();
            },
            copy(){
                sdkNotice.copy("2982250830");
                this.$toast("复制成功");
            }
        }
    }
</script>

<style scoped>
    .kf-item {
        width: 100%;
        display: flex;
        font-size: 13px;
        color: #444444;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
    }
    .sec-button {
        font-size: 12px;
        height: 25px;
        line-height: 25px;
        background-color: #48c9b0;
        color: #ffffff;
        width: 40px;
        text-align: center;
        border-radius: 3px;
        justify-self: right;
    }
</style>
class Tools {
    isEmptyStr(input) {
        if (input === null || input === undefined || input === '') {
            return true;
        }
        return false;
    }

    interfacePropertyToString = (property) => {
        const chaine = property.toString();
        const arr = chaine.match(/[\s\S]*{[\s\S]*\.([^\.; ]*)[ ;\n]*}/);
        return arr[1];
    };

    /**
     * 格式化时间
     timeFormat | date(timestamp, format = "yyyy-mm-dd")
     该函数必须传入第一个参数，第二个参数是可选的，函数返回一个格式化好的时间。
     time <String> 任何合法的时间格式
     format <String> 时间格式，可选。
     默认为yyyy-mm-dd，年为"yyyy"，月为"mm"，日为"dd"，时为"hh"，分为"MM"，秒为"ss"，格式可以自由搭配
     如： yyyy:mm:dd，yyyy-mm-dd，yyyy年mm月dd日，yyyy年mm月dd日 hh时MM分ss秒，yyyy/mm/dd/，MM:ss等组合
     */
    timeFormat(dateTime = null, fmt = 'yyyy-mm-dd') {
        // 如果为null,则格式化当前时间
        if (!dateTime) dateTime = Number(new Date());
        // 如果dateTime长度为10或者13，则为秒和毫秒的时间戳，如果超过13位，则为其他的时间格式
        if (dateTime.toString().length === 10) dateTime *= 1000;
        const date = new Date(dateTime);
        let ret;
        const opt = {
            'y+': date.getFullYear().toString(), // 年
            'm+': (date.getMonth() + 1).toString(), // 月
            'd+': date.getDate().toString(), // 日
            'h+': date.getHours().toString(), // 时
            'M+': date.getMinutes().toString(), // 分
            's+': date.getSeconds().toString(), // 秒
            // 有其他格式化字符需求可以继续添加，必须转化成字符串
        };
        for (const k in opt) {
            ret = new RegExp(`(${k})`).exec(fmt);
            if (ret) {
                // fmt = fmt.replace(ret[1], (ret[1].length === 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, '0')));
            }
        }
        return fmt;
    }

    // 手机号校验
    isPhone(phoneStr) {
        const myreg = /^((13[0-9])|(14[1|4|5|6|7|8|9])|(15([0|1|2|3|5|6|7|8|9]))|(16[2|5|6|7])|(17[0|1|2|3|5|6|7|8])|(18[0-9])|(19[1|8|9]))\d{8}$/;
        if (!myreg.test(phoneStr)) {
            return false;
        }
            return true;
    }

      // 密码校验
    isPassWord(password) {
        const myreg = /^[a-zA-Z0-9~!@#$%^&*()_+.]{6,12}$/;
        if (!myreg.test(password)) {
            return false;
        }
        return true;
    }

    // 身份证号校验
    isCard(cardStr) {
        const myreg = /^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0|1][0-9])([0][1-9]|[1][0|1|2])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X]|[x])$/;
        if (!myreg.test(cardStr)) {
            return false;
        }
            return true;
    }

    // 邮箱校验
    isEmail(emailStr) {
        const myreg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
        if (!myreg.test(emailStr)) {
            return false;
        }
            return true;
    }

    // 信息脱敏
    /**
     *
     * @param {身份号} cardStr
     * @param {手机号} phoneStr
     * @param {姓名} name
     * @param {邮箱} emailStr
     * @param {用户名} Accountname
     */
    idcardDesensitization(cardStr = '', phoneStr = '', name = '', emailStr = '', Accountname = '') {
        let star = '*';
        const len = cardStr.toString().length - 4;
        for (let i = 1; i < len; i++) {
            star += '*';
        }
        return {
            userName: name.replace(/.(?=.)/g, '*'),
            mobile: `${phoneStr.substring(0, 3)}****${phoneStr.substring(7, 11)}`,
            idCard: star + cardStr.substring(14, 18),
            Accountname: Accountname.replace(/.(?=.)/g, '*'),
            email: `${emailStr.substr(0, 2)}****${emailStr.substr(emailStr.indexOf('@'))}`,
        };
    }

    // 验证密码强度 @param {设置的密码} oValue
    passwordStrength(oValue) {
        oValue = oValue.replace(/[\u4E00-\u9FA5]/g, '');
        if (/\d/.test(oValue) && /[a-z]/.test(oValue) && /[A-Z]/.test(oValue)) {
            return {
                type: '2',
                text: '强',
            };
        } if (
            /^\d+$/.test(oValue)
            || /^[A-Z]+$/.test(oValue)
            || /^[a-z]+$/.test(oValue)
        ) {
            return {
                type: '0',
                text: '弱',
            };
        }
            return {
                type: '1',
                text: '中',
            };
    }

    isMobile() {
        //获取浏览器navigator对象的userAgent属性（浏览器用于HTTP请求的用户代理头的值）
        let info = navigator.userAgent;
        //通过正则表达式的test方法判断是否包含“Mobile”字符串
        let isPhone = /mobile/i.test(info);
        //如果包含“Mobile”（是手机设备）则返回true
        return isPhone;
    }

    createUniqKey(){
        var s = [];
        var hexDigits = "0123456789abcdef";
        for (var i = 0; i < 36; i++) {
            s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
        s[8] = s[13] = s[18] = s[23] = "-";

        var uuid = s.join("");
        return uuid;
    }
}
const tools = new Tools();
export default tools;


<template>
  <div>
    <div class="login__box">
      <div class="login__box-container">
        <div class="login-head">
          <label @click="$router.push('/my')" style="display: flex;align-items: center;cursor: pointer;">
            <div class="game-detail-back">
              <img src="../../../static/icon/back.png" />
            </div>
            <div style="font-weight: 700;font-size: 20px;">注册</div>
          </label>
        </div>
        <div class="login-body">
          <div class="login-body-item">
            
            <div class="login-body-item-input-view">
              <input v-model="registerParam.username" class="login-body-item-input" type="text" placeholder="请输入手机号" />
            </div>
            <div class="login-body-item-input-view">
              <input v-model="registerParam.password" class="login-body-item-input" type="password" placeholder="请输入密码" />
            </div>
            <div class="login-body-item-input-view">
              <input v-model="registerParam.confirmPassword" class="login-body-item-input" type="password" placeholder="请确认密码" />
            </div>
            <div class="login-body-item-input-view">
              <div style="display: flex;flex-direction: row;justify-content: space-between;align-items: center;column-gap:10px;">
                <input v-model="registerParam.smsCode" class="login-body-item-input" style="width: calc(100% - 100px)" type="text" placeholder="请输入验证码" />
                <div class="send-verify" v-show="!showCountDown" @click="sendVerifyCode">发送验证码</div>
                <div ref="countDown" class="send-verify" v-show="showCountDown">{{ countDown }}</div>
              </div>
            </div>
            <div class="login-body-item-input-view">
              <input v-model="registerParam.inviteCode" class="login-body-item-input" type="text" placeholder="请邀请码密码(选填)" />
            </div>
            <div class="login-body-item-input-view">
              <div @click="toLoginPage" style="float: right;user-select: none;font-size: 14px;color: #9ad1aa">去登陆</div>
            </div>
          </div>
          <div class="login-body-item">
            <div>
              <van-checkbox style="width: 100%;"
                            v-model="consent"
                            checked-color="#9ad1aa"
                            icon-size="16px">
                <div style="font-size: 14px;color: #b8bec4;">
                  勾选即表示同意
                  <span @click="$router.push('/user/agreement')" style="color: #0c5ab0">《用户协议》</span>
                  &
                  <span @click="$router.push('/privacy')" style="color: #0c5ab0">《隐私协议》</span>
                </div>
              </van-checkbox>
            </div>
            <div>
              <van-button @click="doRegister" round block color="#b3d9b5">注册</van-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tools from "@/utils/Tools";
import Crypto from "@/utils/crypto";
import {sendVerifyCode, toRegister} from "@/api/request";

export default {
  name: "register",
  data(){
    return {
      consent: false,
      registerParam: {
        username: '',
        password: '',
        confirmPassword: '',
        smsCode: '',
        inviteCode: ''
      },
      showCountDown: false,
      countDown: 0,
    }
  },
  methods: {
    doRegister(){
      if(!Tools.isPhone(this.registerParam.username)){
        this.$toast.fail("手机号码无效，请正确填写手机号码")
      }else if(!Tools.isPassWord(this.registerParam.password)){
        this.$toast.fail("密码为6-12位数字、字母、字符")
      }else if(!Tools.isPassWord(this.registerParam.confirmPassword)){
        this.$toast.fail("确认密码为6-12位数字、字母、字符")
      }else if(this.registerParam.password !== this.registerParam.confirmPassword){
        this.$toast.fail("两次输入的密码不一致")
      }else if(!this.registerParam.smsCode){
        this.$toast.fail("请输入验证码")
      }else if(!this.consent){
        this.$toast.fail("为了更好地保障你的合法权益，进入下一步前，请阅读并同意 《用户协议》&《隐私政策》")
      }else {
        let params = {
          username: this.registerParam.username,
          password: Crypto.encrypt(this.registerParam.password),
          smsCode: this.registerParam.smsCode,
          inviteCode: this.registerParam.inviteCode
        }
        toRegister(params).then(res => {
          if(res.data.success){
            this.registerParam = {
              username: '',
              password: '',
              confirmPassword: '',
              smsCode: '',
              inviteCode: ''
            }
            this.$router.replace('/login');
          }else {
            this.$toast.fail(res.data.error.message)
          }
        })
      }
    },
    sendVerifyCode() {
      if(!Tools.isPhone(this.registerParam.username)){
        this.$toast.fail("手机号码无效，请正确填写手机号码")
        return;
      }
      let params = {
        type: 1,
        phone: this.registerParam.username
      }
      sendVerifyCode(params).then(res => {
        if (res.data.success) {
          let time = 120;
          let timer = setInterval(() => {
            this.showCountDown = true;
            this.countDown = time + "s";
            time--;
            if (time < 0) {
              this.showCountDown = false;
              clearInterval(timer);
            }
          }, 1000);
        } else {
          alert(res.data.error.message)
        }
      }).catch(() => {
      })
    },
    toLoginPage(){
      this.$router.push('/login');
    }
  }
}
</script>

<style scoped>
.login__box {
  display: flex;
  align-items: center;
  min-height: 100vh;
  -webkit-box-align: center;
}
.login__box-container {
  width: 500px;
  /*height: auto;*/
  height: 700px;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  margin: 0 auto;
}
.login-head {
  height: 56px;
  padding: 0 10px;
  
  display: flex;
  align-items: center;
}
.login-body {
  display: flex;
  flex-direction: column;
}
.login-body-item:nth-child(2) {
  margin-top: 50px;
}
.login-body-item {
  padding: 0 20px;

  display: flex;
  flex-direction: column;
  row-gap: 15px;
}
.login-body-item-input-view {
  /*margin-top: 10px;*/
}
.login-body-item-input {
  width: calc(100% - 20px);
  border: 0;
  background-color: transparent;
  font-size: 18px;
  font-weight: 600;
  padding: 15px 10px;

  border-bottom: 1px solid #e1e1e1;
  transition: border-bottom-color .2s ease-in-out;
}
.login-body-item-input:focus {
  border-bottom-color: #b3d9b5;
}
.send-verify {
  font-size: 14px;
  color: #9ad1aa;
  cursor: pointer;
}

.game-detail-back {
  margin-right: 5px;
  cursor: pointer;
  
  width: 30px;
  height: 30px;
}
.game-detail-back img {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 499px){
  .login__box-container {
    width: calc(100% - 2px);
    height: calc(100vh - 2px);
    border: 0;
    margin: 0;
    /*overflow: hidden;*/
  }
}
</style>
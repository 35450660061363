<template>
  <div class="sdk-page">
    <div class="sdk-nav-bar">
      <div @click="back" style="width: 40px;height: 40px;display: flex;align-items: center;justify-content: center; position: absolute;">
        <van-icon name="arrow-left" size="20" color="#888888"/>
      </div>
      <div style="margin-left:40px;font-size: 16px;">
        礼包领取
      </div>
      <van-icon @click="close" name="cross" color="#d0d0d0" size="20" class="close-x"/>
    </div>

      <div class="main">
        <div v-if="recordList.length === 0" style="background-color: #FFFFFF;margin-top: 20px;height: 80px;display: flex;justify-content: center;align-items: center;border-radius: 5px;">
            <span class="none-text">暂无领取记录</span>
        </div>

          <div style="margin: 10px 15px;height: calc(100vh - 60px);overflow: hidden;overflow-y: auto;">
            <div v-for="(item,index) in recordList" style="margin-top: 1px;" class="record-list">
              <div @click="open(index)" style="display: flex">
                <img :src="item.imgUrl" style="width: 38px;height: 38px;">
                <div style="display: flex;flex-direction: column;align-items: flex-start;justify-content: space-between;margin-left: 15px;flex: 1">
                  <div style="display: flex;justify-content: space-between;align-items: center;width: 100%;padding-right: 30px;box-sizing: border-box">
                    <span style="font-size: 12px;font-weight: 700;color: #48c9b0;">{{item.giftName}}</span>
                  </div>
                  <span style="font-size: 11px;">{{ item.gameName }}</span>
                </div>
                <van-icon v-show="!item.open" name="arrow-down" color="#888888" />
                <van-icon v-show="item.open" name="arrow-up" color="#888888" />
              </div>
              <div v-if="item.open" style="background-color: #FFFFFF;text-align: left;padding: 8px 25px;font-size: 13px;margin-top: 5px;border-radius: 5px">
                <div style="display: flex;justify-content: space-between;margin-top: 5px;">
                  <span >领取时间</span>
                  <span >{{item.getTime}}</span>
                </div>
                <div v-if="!!item.giftCode" style="display: flex;justify-content: space-between;margin-top: 5px;">
                  <span >礼包码</span>
                  <div style="width: 70%;text-align: right;overflow: auto">
                    <span >{{item.giftCode}}</span>
                  </div>
                </div>
                <van-button v-if="!!item.giftCode" @click="copyCode(item.giftCode)" size="mini" round color="#3DA7FF" style="padding: 0 15px;margin-top: 12px;">
                  <span style="font-size: 13px;font-weight: bold">复制礼包码</span>
                </van-button>
              </div>
            </div>
          </div>
          
          <myloading v-show="showLoading"></myloading>
      </div>
    </div>
</template>

<script>
import Myloading from "@/components/myloading.vue";
import {Toast} from "vant";
import {request, sdkRequest} from "@/api/request";
import sdkNotice from "@/api/sdk-notice";

export default {
  name: "GiftRecord",
  components: {Myloading},
  data(){
    return {
      recordList: [],
      showLoading: false,
    }
  },
  mounted() {
    this.queryData();
  },
  methods: {
    back() {
      this.$router.replace("/sdk/gift");
    },
    close(){
      sdkNotice.close();
    },
    copyCode(v) {
      sdkNotice.copy(v);
      Toast('礼包码已经复制到剪切板');
    },
    queryData() {
      this.showLoading = true;
      let options = {
        url:"/sdk/h5/giftRecordList",
        data: {}
      };
      sdkRequest(options).then(res => {
        this.showLoading = false;
        if (res.data.success) {
          let d = res.data.data;
          d.forEach(function(e, i) {
              e.open = false;
          });
          this.recordList = d;
        }
        else {
          Toast(res.data.error.message);
        }
      });
    },
    open(index) {
      this.recordList[index].open = !this.recordList[index].open;
    },
  }
}
</script>

<style scoped>
    .main{
        overflow-y: auto;
    }
    .none-text{
        font-size: 16px;
        color: #888888;
        text-align: center;
    }
    .record-list{
        background-color: #f2f2f2;
        border-radius: 5px;
        padding: 8px 10px;
    }
</style>
<template>
    <div class="sdk-page">
        <div class="sdk-nav-bar">
            <div @click="back" style="width: 40px;height: 40px;display: flex;align-items: center;justify-content: center; position: absolute;">
                <van-icon name="arrow-left" size="20" color="#888888"/>
            </div>
            <div style="margin-left:40px;font-size: 16px;">
                爽币充值
            </div>
            <van-icon @click="close" name="cross" color="#d0d0d0" size="20" class="close-x"/>
        </div>

        <div class="sdk-page-body" style="padding-top: 5px;height: calc(100vh - 100px);">
            <div style="text-align: left;color: #333333;font-size: 12px;">充值金额 爽币充值比例 1:1</div>
            <van-field  maxlength="10" type="digit" placeholder="请输入充值金额" class="input-view" style="margin-top: 10px;" clearable v-model="amount" />

            <div style="color: #333333;font-size: 12px;margin-top: 15px;text-align: left">请选择支付方式</div>
            <div style="display: flex;align-items: center;justify-content: space-between;margin-top: 10px;">
                <div @click="selectPayment('wx')" class="payment-item" style="" :class="selectedPay === 'wx' && 'payment-active'">
                    <img src="../../assets/pay_wx.png" style="width: 20px;height: 20px;margin-left: 15px;">
                    <span style="margin-left: 10px;">微信</span>
                </div>
                <div @click="selectPayment('ali')" class="payment-item" style="" :class="selectedPay === 'ali' && 'payment-active'">
                    <img src="../../assets/pay_zfb.png" style="width: 20px;height: 20px;margin-left: 15px;">
                    <span style="margin-left: 10px;">支付宝</span>
                </div>
            </div>

        </div>

        <div class="sdk-page-footer">
            <div @click="submit(true)" class="text-button">扫码充值</div>
            <div @click="submit(false)" class="sdk-submit-button">立即充值</div>
        </div>

        <myloading v-show="showLoading"></myloading>
    </div>
</template>

<script>
    import {sdkRequest} from "@/api/request";
    import myloading from '@/components/myloading'
    import {Toast, Dialog} from "vant";
    import '../../static/css/sdkcommon.css';
    import sdkNotice from "@/api/sdk-notice";

    export default {
        name: "SdkRecharge",
        components: {
            myloading
        },
        data: function () {
            return {
                showLoading: false,
                amount: '',
                selectedPay: '',
                payUrl: '',
            }
        },
        mounted() {
            this.userId = localStorage.getItem('accountId');
        },
        methods: {
            back() {
                this.$router.back();
            },
            close() {
                sdkNotice.close();
            },
            selectPayment(payment) {
                this.selectedPay = payment;
            },
            submit(isScan) {
                if (!this.amount) {
                    Toast('请输入充值金额');
                    return ;
                }
                if (parseInt(this.amount) <= 0) {
                    Toast('充值金额必须大于0');
                    return ;
                }
                if (!this.selectedPay) {
                    Toast('请选择支付方式');
                    return ;
                }

                this.showLoading = true;
                let payType;
                if (this.selectedPay === 'wx') {
                    payType = isScan ? 22 : 21;
                }
                else if (this.selectedPay === 'ali') {
                    payType = isScan ? 32 : 31;
                }

                let options = {
                    url:"/sdk/h5/ptbOrder",
                    data: {
                        amount: this.amount,
                        payType: payType,
                    }
                };
                sdkRequest(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        this.payUrl = res.data.data.payString;
                        if (isScan) {
                            sdkNotice.openScanPayPage(this.payUrl, payType, this.amount,res.data.data.orderId);
                        }
                        else {
                            sdkNotice.openUrl(this.payUrl);
                        }
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
        }
    }
</script>

<style scoped>

    .sdk-page-footer {
        width: 80%;
        height: 50px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .text-button {
        font-size: 13px;
        color: #48c9b0;
    }
    .sdk-submit-button {
        background: #48c9b0;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 150px;
        height: 35px;
        font-size: 13px;
        font-weight: bold;
        border-radius: 3px;
        cursor: pointer;
        user-select: none;
    }
    .payment-item {
        width: 110px;
        height: 40px;
        outline: 1px solid #cccccc;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: #333333;
        font-size: 13px;
        position: relative;
    }
    .payment-active {
        outline: 2px solid #48c9b0;
    }
    .img-jb {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 20px;
        height: 18px;
    }
</style>
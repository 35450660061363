import { render, staticRenderFns } from "./contentlist.vue?vue&type=template&id=0dd42eef&scoped=true"
import script from "./contentlist.vue?vue&type=script&lang=js"
export * from "./contentlist.vue?vue&type=script&lang=js"
import style0 from "../../../static/css/game-detail.css?vue&type=style&index=0&id=0dd42eef&prod&scoped=true&lang=css&external"
import style1 from "../../../static/css/gift.css?vue&type=style&index=1&id=0dd42eef&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0dd42eef",
  null
  
)

export default component.exports
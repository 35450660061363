import { render, staticRenderFns } from "./GameMain.vue?vue&type=template&id=37eb0dd0&scoped=true"
import script from "./GameMain.vue?vue&type=script&lang=js"
export * from "./GameMain.vue?vue&type=script&lang=js"
import style0 from "./GameMain.vue?vue&type=style&index=0&id=37eb0dd0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37eb0dd0",
  null
  
)

export default component.exports
<template>
    <div class="diy-dialog-background">
        <div class="diy-dialog">
            <div style="text-align: center;height: 30px">支付提示</div>
            <van-divider/>
            <div style="height: 70px;display: flex;align-items: center;text-align: center;padding: 0 15px;">
                <span style="">是否打开支付页面完成支付？</span>
            </div>
            <van-divider/>
            <div class="diy-dialog-buttons">
                <div class="button-div">
                    <a @click="close()"  style="color: #888888">取消</a>
                </div>
                <div style="width: 1px;height: 40px;background-color: #ebedf0"></div>
                <div class="button-div">
                    <a target="_blank" :href="payUrl" @click="close()" >去支付</a>
                </div>

            </div>

        </div>
    </div>

</template>

<script>
    export default {
        name: "PayConfirm",
        data() {
            return {
                payUrl: '',
            }
        },
        methods: {
            close() {
                this.$parent.close('payConfirm');
            },
        }
    }
</script>

<style scoped>
    .diy-dialog-background {
        width: 100%;
        height: 100vh;
        background-color: rgba(42, 42, 42, 0.17);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .diy-dialog {
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px 8px 1px 8px;
        text-align: left;
        font-size: 14px;
        width: 70%;
        border-radius: 10px;
    }

    .diy-dialog-buttons {
        font-size: 14px;
        display: flex;
        justify-content: center;
        margin-bottom: 0;
    }

    .button-div {
        flex: 1;
        text-align: center;
        height: 40px;
        line-height: 40px;
    }

    a {
        color: #1783d6;
    }

    .van-divider--hairline {
        margin: 0 0;
    }

</style>